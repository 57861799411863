import React from "react";
import { Link } from 'gatsby'

const ButtonOutline = ({ children, link }) => {
    return (
        <>
            <Link to={link} className="py-2 px-6 flex max-w-max justify-center items-center bg-transparent border border-peru-600 hover:bg-peru-500 focus:ring-peru-200 focus:ring-offset-peru-100 text-peru-600 hover:text-white transition ease-in duration-200 text-center text-sm font-light uppercase tracking-wider shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                {children}
            </Link>
        </>
    )
};

export default ButtonOutline